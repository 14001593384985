<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { PageHero } from '$lib/contentful/models/page-hero';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import PageNavigation from '../page-navigation/page-navigation.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import MarketoForm from '$lib/components/marketo-form/marketo-form.svelte';
  import { twMerge as merge } from 'tailwind-merge';

  export let content: PageHero | undefined;
  export let home: boolean = false;
  export let container: boolean = false;
</script>

{#if content}
  {@const {
    eyebrow,
    breadcrumbText,
    breadcrumbHref,
    heading,
    body,
    links,
    callsToAction = [],
    entityId,
    background,
    alignment,
    image,
    marketoForm,
    imageCarousel,
  } = content}

  {@const span = home || imageCarousel ? 5 : 10}
  <Grid layout="rows" as="div" {background}>
    <Grid
      layout="columns"
      as="div"
      class="flex items-center {imageCarousel ? 'no-padding' : ''}"
    >
      <Box
        as="div"
        {span}
        class="max-xl:py-8 xl:py-24 flex flex-row items-center gap-8 {alignment ===
        'center'
          ? 'justify-center'
          : 'justify-start'}"
      >
        <div
          class={merge(
            'hero-content',
            alignment,
            container && 'container mx-auto',
          )}
          class:home
        >
          {#if eyebrow}
            <div class="flex gap-2">
              <Text
                as="h4"
                variant="eyebrow-16"
                class="z-10"
                {background}
                {entityId}
                fieldId="eyebrow"
              >
                <slot name="eyebrow">
                  {#if breadcrumbHref}
                    <a href={breadcrumbHref}>{eyebrow}</a>
                  {:else}
                    {eyebrow}
                  {/if}
                </slot>
                {#if breadcrumbText}
                  &gt;
                  <span>
                    {breadcrumbText}
                  </span>
                {/if}
              </Text>
            </div>
          {/if}
          {#if image && alignment === 'center'}
            <ContentfulImage
              src={image.url}
              alt={image.description}
              fieldId="image"
              {entityId}
            />
          {/if}
          {#if heading}
            <Text
              as="h1"
              variant={home ? 'display-68' : 'display-104'}
              class="z-10"
              {background}
              {entityId}
              fieldId="heading"
            >
              <slot name="heading">{heading}</slot>
            </Text>
          {/if}
          {#if image && alignment === 'left'}
            <ContentfulImage
              pictureClass="sm:hidden"
              src={image.url}
              alt={image.description}
              fieldId="image"
              {entityId}
            />
          {/if}
          {#if body || $$slots.body}
            <slot name="body">
              <RichText
                {background}
                fieldId="body"
                {entityId}
                text={body}
                class="z-10"
              />
            </slot>
          {/if}
          {#if marketoForm}
            <MarketoForm
              class="max-lg:w-full max-xl:w-2/3 xl:w-1/2"
              content={marketoForm}
            />
          {/if}
          <div class="hero-cta {alignment}">
            <slot name="ctas">
              {#each callsToAction as { text, ...rest }}
                <Button {...rest}>{text}</Button>
              {/each}
            </slot>
          </div>
        </div>
        {#if image && alignment === 'left'}
          <ContentfulImage
            pictureClass="col-span-3 col-start-9 max-sm:hidden"
            src={image.url}
            alt={image.description}
            fieldId="image"
            {entityId}
          />
        {/if}
      </Box>
      {#if imageCarousel}
        {@const left = imageCarousel.slice(
          0,
          Math.floor(imageCarousel.length / 2),
        )}
        {@const right = imageCarousel.slice(
          Math.round(imageCarousel.length / 2),
          imageCarousel.length,
        )}
        <Box
          as="div"
          offset={8}
          span={5}
          class="relative h-full max-sm:h-[20rem]"
        >
          <div class="image-carousel-container lg">
            <div class="image-carousel">
              {#each left as image}
                <ContentfulImage
                  pictureClass="max-sm:shrink-0 lg:-translate-y-1/2"
                  src={image.url}
                  alt={image.description ?? image.title}
                  width={400}
                  height={300}
                  fitStrategy="fill"
                />
              {/each}
            </div>
            <div class="image-carousel" aria-hidden="true">
              {#each right as image}
                <ContentfulImage
                  pictureClass="max-sm:shrink-0"
                  src={image.url}
                  alt={image.description ?? image.title}
                  width={400}
                  height={300}
                  fitStrategy="fill"
                />
              {/each}
            </div>
            <div class="image-carousel">
              {#each left as image}
                <ContentfulImage
                  pictureClass="max-sm:shrink-0 lg:-translate-y-1/2"
                  src={image.url}
                  alt={image.description ?? image.title}
                  width={400}
                  height={300}
                  fitStrategy="fill"
                />
              {/each}
            </div>
            <div class="image-carousel" aria-hidden="true">
              {#each right as image}
                <ContentfulImage
                  pictureClass="max-sm:shrink-0"
                  src={image.url}
                  alt={image.description ?? image.title}
                  width={400}
                  height={300}
                  fitStrategy="fill"
                />
              {/each}
            </div>
          </div>
          <div class="image-carousel-container lt-lg">
            <div class="image-carousel">
              {#each imageCarousel as image}
                <ContentfulImage
                  pictureClass="max-sm:shrink-0 sm:w-[300px]"
                  src={image.url}
                  alt={image.description ?? image.title}
                  width={400}
                  height={300}
                  fitStrategy="fill"
                />
              {/each}
            </div>
            <div class="image-carousel" aria-hidden="true">
              {#each imageCarousel as image}
                <ContentfulImage
                  pictureClass="max-sm:shrink-0 sm:w-[300px]"
                  src={image.url}
                  alt={image.description ?? image.title}
                  width={400}
                  height={300}
                  fitStrategy="fill"
                />
              {/each}
            </div>
          </div>
        </Box>
      {/if}
      {#if $$slots['home-animation']}
        <Box as="div" offset={9} span={6} class="h-full py-16">
          <slot name="home-animation" />
        </Box>
      {/if}
    </Grid>
    {#if links}
      <PageNavigation {links} />
    {/if}
  </Grid>
{/if}

<style lang="postcss">
  @keyframes image-carousel-vertical {
    from {
      transform: translateY(0%);
    }

    to {
      transform: translateY(calc(-100% - 1rem));
    }
  }

  @keyframes image-carousel-horizontal {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(
        calc(-100% - 1rem)
      ); /* -1 rem accounts for the gap-4 */
    }
  }

  .hero-content {
    @apply flex flex-col sm:gap-8 max-sm:gap-2;

    &:not(.home) {
      @apply xl:max-w-[75%] 2xl:max-w-[66%];
    }

    &.left {
      @apply items-start;
    }

    &.center {
      @apply items-center w-full text-center;
    }
  }

  .image-carousel-container {
    @apply absolute top-0 h-full overflow-hidden gap-4 sm:grid;

    &.lg {
      @apply max-lg:hidden lg:grid-cols-2;
    }

    &.lt-lg {
      @apply max-sm:flex sm:grid-cols-1 lg:hidden max-sm:-left-5 sm:right-0;
    }
  }

  .image-carousel {
    @apply flex sm:flex-col max-sm:flex-row max-sm:w-max max-sm:shrink-0 gap-4 lg:animate-[image-carousel-vertical_60s_linear_infinite] sm:animate-[image-carousel-vertical_120s_linear_infinite] max-sm:animate-[image-carousel-horizontal_120s_linear_infinite];
  }

  .hero-cta {
    @apply flex flex-row items-center gap-4 flex-wrap;

    &.center {
      @apply justify-center;
    }
  }
</style>
