<script lang="ts">
  import PageNavigationLink from './page-navigation-link.svelte';
  import type { PageNavigationLink as IPageNavLink } from '$lib/contentful/models/page-navigation-link';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Text from '../text/text.svelte';
  import Icon from '../icon/icon.svelte';

  export let links: IPageNavLink[] = [];
  let open = false;

  const toggleQuickNav = () => {
    open = !open;
  };
</script>

<Grid layout="columns" as="nav" background="deep-blue" class="no-padding">
  <Box as="div">
    <div
      class="max-sm:hidden max-lg:p-8 max-lg:grid max-lg:grid-cols-2 gap-6 lg:flex lg:flex-row lg:items-start lg:justify-center lg:py-8"
    >
      {#each links as link}
        <PageNavigationLink {link} />
      {/each}
    </div>
    <div class="max-sm:flex max-sm:flex-col max-sm:gap-6 max-sm:py-8 sm:hidden">
      <button
        on:click={toggleQuickNav}
        aria-label="Toggle quick nav"
        class="flex flex-row justify-between hover:text-indigo-100"
      >
        <Text as="p" variant="heading-24">Quicknav</Text>
        <Icon name={open ? 'chevron-up' : 'chevron-down'} />
      </button>
      {#if open}
        {#each links as link}
          <PageNavigationLink {link} />
        {/each}
      {/if}
    </div>
  </Box>
</Grid>
